/* * {border: 1px solid red} */

.Prices {
    box-sizing: border-box;
    width: 800px;
    margin: 0 auto;
    padding-bottom: 80px;
}
.Prices h3 {
    margin: 16px auto 24px;
    color: hsl(201, 63%, 24%);
    font-size: 28px;
    text-align: center;
    font-weight: 1000;
}
.Prices table {
    margin: 10px auto;
    text-align: right;
    border: 2px solid black;
    /* padding: 4px; */
    border-radius: 10px;
}
.Prices tbody {
    margin: 0 auto;
    width: 570px;
    margin-top: 5px;
}
.Prices tbody tr:nth-child(odd) {
    background-color: hsl(74, 80%, 25%);
}
.Prices tbody tr:nth-child(even) {
    background-color: hsl(201, 63%, 28%);
}
.Prices tr:first-child td:first-child {
    border-radius: 10px 0 0 0;
}
.Prices tr:first-child td:last-child {
    border-radius: 0 10px 0 0;
}
.Prices tr:last-child td:first-child {
    border-radius: 0 0 0 10px;
}
.Prices tr:last-child td:last-child {
    border-radius: 0 0 10px 0;
}
.Prices tr td {
    padding: 15px 30px;
}

@media (max-width: 830px) {
    .Prices {
        width: 90vw;
    }
}