#root {
    background-color: hsl(74, 80%, 36%);
}
#App {
    box-sizing: border-box;
    position: relative;
    padding-bottom: 60px; /* the place for the Footer */
}
@media(max-width: 1000px) {
    #App {
        padding-bottom: 80px; /* the place for the Footer */
    }
}
* div {
    font-family: cinzel;
    color: white;
}
span {
    font-family: cinzel-medium;
    font-weight: 1000;
    color: hsl(201, 63%, 24%);
}

/* ---Scrollbar--- */
::-webkit-scrollbar {
    width: 17px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 12px hsl(74, 80%, 26%);
}
::-webkit-scrollbar-thumb {
    background-color: hsl(74, 80%, 26%);
}
::-webkit-scrollbar-thumb:hover {
    background: hsl(74, 80%, 31%);
  }