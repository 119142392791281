#Thanks {
    width: 630px;
    margin: 100px auto;
    min-height: calc(100vh - 705px);
}
#Thanks p {
    text-align: center;
    font-size: 20px;
}
@media (max-width: 1430px) {
    #Thanks {
        margin: 100px 0 0 390px;
    }
}
@media (max-width: 1110px) {
    #Thanks {
        margin: 100px 0 0 340px;
    }
}
@media (max-width: 1000px) {
    #Thanks {
        margin: 100px auto;
    }
}
@media (max-width: 680px) {
    #Thanks {
        width: 400px;
    }
}
@media (max-width: 490px) {
    #Thanks {
        margin-top: 40px;
        width: 300px;
    }
    #Thanks p {
        font-size: 18px;
    }
}
@media (max-width: 330px) {
    #Thanks {
        margin-top: 40px;
        width: 270px;
    }
    #Thanks p {
        font-size: 16px;
    }
}