#Contact {
    width: 800px;
    margin: 0 auto;
}
#Contact__desc,
#Contact h1 {
    text-align: center;
}
#Contact h1 {
    color: hsl(201, 63%, 26%);
}
#Contact a {
    font-weight: 1000;
    text-decoration: none;
    display: block;
    width: 300px;
    text-align: center;
    margin: 15px auto;
    border: 2px solid black;
    border-radius: 15px;
    padding: 5px 8px;
}
#Contact a[href*="odnowa"] {
    background-color: hsl(201, 63%, 46%);
    color: hsl(201, 63%, 16%);
}
#Contact a[href*="odnowa"]:hover {
    background-color: hsl(201, 63%, 66%);
    transition: background-color 0.5s;
}
#Contact a[href*="angel"] {
    background-color: hsl(300, 100%, 35%);
    color: hsl(300, 100%, 15%);
}
#Contact a[href*="angel"]:hover {
    background-color: hsl(300, 100%, 65%);
    transition: background-color 0.5s;
}
.Contact__wrapper {
    width: 256px;
    height: 30px;
    display: flex;
    margin: 10px auto;
}
.Contact__wrapper img,
.Contact__wrapper p {
    display: inline-block;
}
.Contact__wrapper img {
    height: 20px;
    padding-top: 5px;
}
.Contact__wrapper p {
    line-height: 30px;
    padding-left: 10px;
    margin: 0;
}
.Contact__all-inputs input {
    font-family: cinzel;
    display: inline-block;
    width: 258px;
    border: 1px solid black;
    border-radius: 3px;
    margin: 1px 1px;
    line-height: 20px;
}
.Contact__all-inputs {
    display: flex;
    justify-content: space-between;
}
#Contact form textarea {
    font-family: cinzel;
    height: 160px;
    width: 789px;
    border: 1px solid black;
    border-radius: 3px;
    margin: 1px;
    line-height: 20px;
}
#Contact form button {
    font-family: cinzel;
    display: block;
    margin: 10px auto;
    color: hsl(201, 63%, 16%);
    background-color: hsl(201, 63%, 46%);
    border: 2px solid hsl(201, 63%, 16%);
    border-radius: 20px;
    padding: 5px 12px;
    font-weight: 1000;
    font-size: 16px;
}
#Contact form button:hover {
    background-color: hsl(201, 63%, 66%);
    transition: 0.5s background-color;
    cursor: grab;
}
@media(max-width: 1550px) {
    #Contact {
        margin-left: 360px;
    }
}
@media(max-width: 1230px) {
    #Contact {
        width: 600px; 
    }
    #Contact form {
        width: 600px;
    }
    .Contact__all-inputs input {
        width: 192px;
    }
    #Contact form textarea {
        width: 590px;
    }
}
@media(max-width: 1000px) {
    #Contact {
        margin: 0 auto;
    }
}
@media(max-width: 630px) {
    #Contact {
        width: 97%; 
    }
    #Contact form {
        width: 100%;
    }
    .Contact__all-inputs input {
        width: 32%;
    }
    .Contact__all-inputs {
        width: 99%;
    }
    #Contact form textarea {
        width: 98%;
        margin: 1px 0;
    }
    /* Buttons with hyperlink */
    #Contact a {
        font-size: 13px;
        width: 250px;
    }
}
@media(max-width: 400px) {
    .Contact__all-inputs {
        display: flex;
        flex-direction: column;
    }
    .Contact__all-inputs input {
        display: block;
        width: 98.5%;
    }   
}