.Footer {
    bottom: 0;
    z-index: 1;
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    line-height: 30px;
    font-family: lato;
    background-color: hsl(201, 63%, 46%);
    font-size: 15px;
}
.Footer::before {
    content: 'Copyright © 2023 ODNOWA Aneta Kubik';
    padding: 0 5px;
}
.Footer::after {
    content: 'Realizacja ARDEO Grzegorz Muszyński';
    padding: 0 5px;
}

@media(max-width: 600px) {
    .Footer {
        flex-direction: column;
    }
}