#Opinions {
    margin-top: 20px;
    width: 900px;
    margin: 0 auto;
    font-size: 21px;
}
#Opinions h2 {
    text-align: center;
    color: hsl(201, 63%, 26%);
    font-size: 28px;
    max-width: 700px;
    margin: 30px auto 30px;
}
#Opinions h1 {
    text-align: center;
    font-size: 32px;
    text-decoration: underline 1.5px;
    text-underline-offset: 8px;
}
#Opinions__button {
    display: block;
    width: 175px;
    height: 35px;
    line-height: 35px;
    text-align: center; 
    text-decoration: none;
    font-weight: 800;
    margin: 10px auto 40px;
    color: hsl(240, 100%, 30%);
    background-color: hsl(201, 63%, 56%);
    border-radius: 15px;
    border: 1px solid hsl(240, 100%, 30%);
}
#Opinions__button:hover {
    background-color: hsl(201, 63%, 76%);
    transition: background-color 0.5s;
}
#Opinions__reviews-google {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
#Opinions__reviews-google img {
    width: 445px;
    margin: 1px auto;
}
#Opinions__reviews-booksy {
    max-width: 800px;
    margin: 2px auto;
}
#Opinions__reviews-booksy img {
    width: 100%;
}

@media (max-width: 1590px) {
    #Opinions {
        margin-left: 330px;
    }
}
@media (max-width: 1300px) {
    #Opinions {
        width: calc(100% - 340px);
        padding-right: 10px;
    }
    #Opinions__reviews-google {
        width: 100%;
        margin: 3px auto;
        flex-direction: column;
    }
    #Opinions__reviews-google img {
        width: 445px;
        margin-top: 3px;
        display: block;
    }
}
@media (max-width: 1000px) {
    #Opinions {
        margin: 0 auto;
        padding: 0;
    }
    #Opinions h2 {
        width: 450px;
    }
}
@media (max-width: 810px) {
    #Opinions {
        margin: 0 auto;
        padding: 0;
        width: 450px;
    }
    #Opinions__reviews-google img {
        width: 100%;
    }
}
@media (max-width: 490px) {
    #Opinions {
        width: 97%;
    }
    #Opinions h2 {
        width: 410px;
        font-size: 25px;
    }
    #Opinions__reviews-google img {
        width: 100%;
    }
    #Opinions__reviews-booksy img {
        margin: -3px 0;
    }
}
@media (max-width: 490px) {
    #Opinions h2 {
        width: 350px;
        font-size: 22px;
    }
    #Opinions h1 {
        font-size: 24px;
    }
}
@media (max-width: 380px) {
    #Opinions h2 {
        width: 290px;
        font-size: 18px;
    }
}
@media (max-width: 310px) {
    #Opinions h2 {
        width: 250px;
        font-size: 16px;
    }
    #Opinions h1 {
        font-size: 20px;
    }
}