*{
    /* border: 1px solid red; */
}
#Header__wrapper {
    width: 580px;
}
#Header video {
    width: 100%;
    object-fit: cover;
    height: 500px;
    position: relative;
    z-index: 2;
    filter: brightness(60%);
}
#Header img {
    width: 600px;
    height: 300px;
}
#Header .Logo {
    position: absolute;
    top: 0;
    z-index: 3;
    width: 470px;
    height: 300px;
    display: block;
    margin-top: 50px;
    margin-left: calc(50vw - 250px);
}
#Header__wrapper__icons {
    position: absolute;
    top: 0;
    z-index: 3;
    margin-top: 445px;
    margin-left: calc(50vw - 150px);
    width: 270px;
    display: flex;
    justify-content: space-between;
}
#Header__wrapper__icons :nth-child(1) img, 
#Header__wrapper__icons :nth-child(2) img {
    width: 40px;
    height: 40px;
}
#Header__wrapper__icons :nth-child(3) img {
    width: 160px;
    height: 40px;
}
@media(max-width: 1130px) {
    #Header__wrapper {
        margin-left: 100px;
    }
}
@media(max-width: 1000px) {
    #Header__wrapper {
        margin-left: 0;
    }
}
@media(max-width: 500px) {
    #Header .Logo {
        margin-left: 0;
        padding: 3%;
        width: 94%;
    }
    #Header__wrapper__icons {
        width: 190px;
        margin-left: calc(50vw - 85px);
    }
    #Header__wrapper__icons :nth-child(1) img, 
    #Header__wrapper__icons :nth-child(2) img {
        width: 30px;
        height: 30px;
    }
    #Header__wrapper__icons :nth-child(3) img {
        width: 120px;
        height: 30px;
    }
}